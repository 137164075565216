<script setup lang="ts">
import { useLocalePath } from '#i18n'

const localePath = useLocalePath()

const isToggleActive = ref(false)
const onToggle = () => {
  isToggleActive.value = !isToggleActive.value
}

const router = useRouter()
const authenticationStore = useAuthenticationStore()
const { fullName, isAuthenticated } = authenticationStore

const onLogout = () => {
  authenticationStore.logout()
  router.push(localePath({ name: 'login' }))
}
</script>

<template>
  <div class="AppMenu h-full p-4">
    <div class="AppMenu__mobile md:hidden">
      <div class="flex justify-between items-center">
        <nuxt-link :to="localePath({ name: 'levels' })">
          <div class="AppMenu__logo">
            <picture>
              <source
                srcset="/img/logo-black@1.webp 1x, /img/logo-black@2.webp 2x, /img/logo-black@3.webp 3x"
                type="image/webp"
              >
              <img
                src="/img/logo-black@1.webp"
                class="w-32"
                alt="Sixpack Logo"
              >
            </picture>
          </div>
        </nuxt-link>
        <div
          class="tham tham-e-squeeze tham-w-6"
          :class="{ 'tham-active': isToggleActive }"
          @click="onToggle"
        >
          <div class="tham-box">
            <div class="tham-inner" />
          </div>
        </div>
      </div>
      <div
        v-if="isToggleActive"
        class="w-full pt-4"
      >
        <div class="AppMenu__items">
          <LayoutAppMenuItems
            @toggle="onToggle"
          />
        </div>
        <nuxt-link
          :to="localePath({ name: 'contact' })"
          class=" bg-indigo-400 block w-full mt-4 mb-4 text-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onToggle"
        >
          NAPSAT ANDĚLOVI
        </nuxt-link>
        <div
          class="btn btn--black btn--md uppercase text-center"
          @click="onLogout"
        >
          Odhlásit
        </div>
      </div>
    </div>
    <div class="AppMenu__desktop h-full hidden md:flex flex-col items-stretch justify-between">
      <nuxt-link :to="localePath({ name: 'levels' })">
        <div class="AppMenu__logo mb-8">
          <picture>
            <source
              srcset="/img/logo-black@1.webp 1x, /img/logo-black@2.webp 2x, /img/logo-black@3.webp 3x"
              type="image/webp"
            >
            <img
              src="/img/logo-black@1.webp"
              class="w-full"
              alt="Sixpack Logo"
            >
          </picture>
        </div>
      </nuxt-link>
      <div class="AppMenu__items">
        <LayoutAppMenuItems
          @toggle="onToggle"
        />
      </div>
      <div class="mt-5">
        <client-only>
          <MoleculeUserProgressBar />
        </client-only>
      </div>
      <div class="grow" />
      <nuxt-link
        :to="localePath({ name: 'contact' })"
        class=" bg-indigo-400 mb-8 text-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        NAPSAT ANDĚLOVI
      </nuxt-link>
      <div class="AppMenu__profile flex justify-between items-center">
        <ClientOnly>
          <div class="text-black font-bold">
            {{ fullName }}
          </div>
        </ClientOnly>
        <div
          class="btn btn--black btn--sm"
          @click="onLogout"
        >
          Odhlásit
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.AppMenu__item {
  @apply text-black font-bold underline;

  &.router-link-active {
    @apply no-underline;
  }
}
</style>
